<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="项目名称" help="20字以内">
							<a-input v-model="form.name" :maxLength="30" placeholder="输入项目名称" style="width: 480px;"></a-input>
						</a-form-model-item>

						
						<a-form-model-item label="展示已约人数">
							<a-input-number v-model="form.fictitious_take_count" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item label="排序" help="数值越大排序越高">
							<a-input-number v-model="form.sort" placeholder="请输入" :precision="0" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<!-- <a-form-model-item required label="计价单位">
							<a-radio-group v-model="form.valuation_unit">
								<a-radio :value="'hour'">小时</a-radio>
								<a-radio :value="'square'">平方</a-radio>
							</a-radio-group>
						</a-form-model-item> -->
						<a-form-model-item required label="项目分类">
							<a-tree-select v-model="form.project_type_id" style="width: 200px"
										   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="type_list"
										   placeholder="请选择" >
							</a-tree-select>
						</a-form-model-item>
						<a-form-model-item required label="市场价">
							<a-input-number v-model="form.original_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						<a-form-model-item required label="门店售价">
							<a-input-number v-model="form.market_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item required label="会员价格">
							<a-input-number v-model="form.price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
						</a-form-model-item>
						
						<a-form-model-item required label="是否首推">
							<a-radio-group v-model="form.is_tui">
								<a-radio :value="0">否</a-radio>
								<a-radio :value="1">是</a-radio>
							</a-radio-group>
						</a-form-model-item>
						
						<a-form-model-item required label="规格">
							<a-radio-group v-model="form.is_have_sku">
								<a-radio :value="0">无</a-radio>
								<a-radio :value="1">有</a-radio>
							</a-radio-group>
							
							
							<div class="mt10" v-if="form.is_have_sku==1">
								<table class="wxb-table list" style="width: 100%;">
									<thead>
										<th>规格名称</th>
										<th>门店售价</th>
										<th>会员价格</th>
										<th>操作</th>
									</thead>
									<tbody>
										<tr v-for="(item,index) in form.sku">
											<td>
												<a-input  placeholder="请输入" v-model="item.name"></a-input>
											</td>
											<td>
												<a-input-number v-model="item.market_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
											</td>
											<td>
												<a-input-number v-model="item.price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
											</td>
											<td>
												<div class="clickAct" @click="delSkuItem(index)">
													<i class="iconfont iconbtn_close ft20 cl-notice "/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="">
									<a-button type="primary" icon="plus" ghost @click="addSkuItem()">添加</a-button>
								</div>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="缩略图" >
							<div class="ft12 ftw400 cl-notice">建议上传300x240px</div>
							<div class="mt10 ml20">
								<upload-img v-model="form.cover_img"></upload-img>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="详情页轮播图" >
							<div class="ft12 ftw400 cl-notice">最多可传5张，建议上传750x750px</div>
							<div class="mt10 ml20">
								<upload-list v-model="form.rotation"></upload-list>
							</div>
						</a-form-model-item>
						
						<div class="edit-project-line mb40"></div>
						<a-form-model-item label="服务人员资质"  class="mt20">
							<a-checkbox-group
									v-model="form.add_value_jurisdiction"
									:options="jurisdiction"
									:value="form.add_value_jurisdiction"
									:default-value="form.add_value_jurisdiction"
							>
							</a-checkbox-group>
							<br />
						</a-form-model-item>

						<a-form-model-item label="服务类型" required class="mt20">
							<a-checkbox v-model="form.is_door_service" >
								上门服务
							</a-checkbox>
							<a-checkbox v-model="form.is_store_service" >
								到店服务
							</a-checkbox>
							<!--
							<a-checkbox v-model="form.is_online_service" >
								在线服务
							</a-checkbox>
							-->
						</a-form-model-item>


						<a-form-model-item   label="服务时长范围" required class="mt20">
							<div style="margin-bottom: 16px;width: 230px;display: inline-block">
								<a-input v-model="form.shortest_time" addon-before="最少服务时长" addon-after="分钟"  />
							</div>
							-
							<div style="margin-bottom: 16px;width: 230px;display: inline-block">
								<a-input v-model="form.long_time" addon-before="最长服务时长" addon-after="分钟"  />
							</div>

						</a-form-model-item>
						<a-form-model-item   label="关联服务规范要求"  class="mt20">
							<a-select show-search v-model="form.article_id"  :filter-option="filterOption" style="width: 236px"
									  placeholder="请选择关联服务规范要求"
									  :options="article_list"
							>

							</a-select>
						</a-form-model-item>
						<a-form-model-item v-if="source_shop_id==0" label="审核状态">
							<a-radio-group v-model="form.audit_status">
								<a-radio :value="1">通过审核</a-radio>
								<a-radio :value="0">待审核</a-radio>
								<a-radio :value="-1">审核拒绝</a-radio>
							</a-radio-group>
						</a-form-model-item>
						<a-form-model-item v-if="form.audit_status==1 || form.parent_id>0" label="是否上架">
							<a-switch v-model="form.status" />
						</a-form-model-item>
						<a-form-model-item label="服务详情" required class="mt20">
							<html-edit v-model="form.detail"></html-edit>
						</a-form-model-item>

						<div class="edit-project-line"></div>

						<a-form-model-item label="预约须知" required>
							<div class="ft12 ftw400 cl-notice">不填就显示默认内容，可修改</div>
							
							<a-textarea  v-model="form.appointment_info" :rows="11" />
						</a-form-model-item>
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadImg from '../../components/upload/upload.vue';
	import uploadList from '../../components/upload/list.vue';
	import  htmlEdit from '../../components/html.vue';
	export default{
		components:{
			uploadImg,
			uploadList,
			htmlEdit
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				project_id:0,
				type_list:[],
                source_shop_id:1,
                jurisdiction:[],
                article_list:[{key:0,title:"请选择"}],
				form: {
                    add_value_jurisdiction:[],
					name:'',
                    shortest_time:'',
                    long_time:'',
                    status:true,
					project_type_id:undefined,
					sort:'',
					// valuation_unit:'hour',
					market_price:'',
                    original_price:'',
					price:'',
					fictitious_take_count:'',
					is_tui:0,
                    article_id:0,
					is_have_sku:0,
                    is_store_service:false,
                    is_door_service:false,
                    is_online_service:false,
					sku:[],
                    audit_status:0,
                    parent_id:0,
					cover_img:'',
					rotation:[],
					detail:'',
					appointment_info:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.project_id=0;
			} else {
				this.project_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.project_id==0){
					return '添加项目';
				}else{
					return '编辑项目';
				}
			}
		},
		methods:{
            filterOption(input, option){

                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showProject',{
					project_id:this.project_id
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.type_list=res.type_list;
					this.jurisdiction=res.aptitude_list;
					this.article_list=res.article_list;
                    this.source_shop_id = res.shop_id;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveProject',{
					project_id:this.project_id,
					name: this.form.name,
					project_type_id: this.form.project_type_id,
                    shortest_time: this.form.shortest_time,
                    long_time: this.form.long_time,
					sort:this.form.sort,
					// valuation_unit:this.form.valuation_unit,
					market_price:this.form.market_price,

					price: this.form.price,
                    original_price: this.form.original_price,
                    status: this.form.status ? 1 :-1,
                    audit_status: this.form.audit_status ,
					fictitious_take_count:this.form.fictitious_take_count,
					is_tui: this.form.is_tui,
					is_have_sku: this.form.is_have_sku,
					sku: JSON.stringify(this.form.sku),
					cover_img: this.form.cover_img,
					rotation: this.form.rotation,
					detail:this.form.detail,
                    article_id:this.form.article_id,
                    is_online_service:this.form.is_online_service!=1 ? 0 :1 ,
                    is_door_service:this.form.is_door_service!=1 ? 0 :1,
                    is_store_service:this.form.is_store_service!=1 ? 0 :1,
                    add_value_jurisdiction:this.form.add_value_jurisdiction.join(","),
					appointment_info:this.form.appointment_info,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$router.go(-1);
					});
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			addSkuItem(){
				this.form.sku.push({
					name:'',
					original_price:'',
					vip_price:'',
					yu_price:'',
					quota:'',
				})
			},
			
			delSkuItem(index){
				this.form.sku.splice(index,1);
			},


		}
	}
</script>

<style>
	.edit-project-line{
		width: 100%;
		height: 1px;
		background: #EBEDF5;
	}
	
	.edit-project-details-text {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #5333FF;
	}
	
	.mobile-content{
		width: 750px;
	}
	.mobile-content{
		
	}
	
	.mobile-item{
		position: relative;
	}
	.mobile-item .action{
		position: absolute;
		left: 0;
		top: -15px;
		width: 100%;
		z-index: 2;
	}
	.del-action{
		width: 30px;
		height: 30px;
		background: rgba(0,0,0,.5);
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#FFFFFF;
		position: absolute;
		right: -15px;
		top:-15px;
		z-index: 3;
	}
	.up-action,.down-action{
		width: 30px;
		height: 30px;
		background: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color:#007AFF;
		box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);
	
	}
</style>